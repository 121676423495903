import { createSharedComposable } from '@vueuse/shared';
import { useApi, type PaginationQuery, type PaginatedResponse } from '../../../../services/api';
import { Download } from '../../interfaces';

export const useDownloadsApi = createSharedComposable(() => {
  const { transport } = useApi();

  function getDownloads(query?: PaginationQuery & { type?: string; status?: string }) {
    return transport.get<PaginatedResponse<Download>>('v1/downloads', { query });
  }

  function getDownloadsByIds(ids: string[]) {
    return transport.post<PaginatedResponse<Download>>('v1/downloads/by_ids', {
      ids,
    });
  }

  function getDownloadLink(id: string) {
    return transport.get<{ url: string }>(`/v1/downloads/${id}/download`);
  }

  return {
    getDownloads,
    getDownloadsByIds,
    getDownloadLink,
  };
});
