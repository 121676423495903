import { ref } from 'vue';
import { isObject } from 'lodash-es';
import { TransportError, TransportResponse } from '../services/api';
import { useI18n } from '../services/i18n';

export enum RequestErrorType {
  ServerError = 'ServerError',
  BadRequest = 'BadRequest',
}

export interface RequestError {
  message: string;
  type: RequestErrorType;
  originalResponse?: {
    status: number;
    statusText: string;
    data: any;
    headers: TransportResponse['headers'];
  };
}

export function useRequestError() {
  const { t } = useI18n();

  function getTransportErrorMessage(error: TransportError) {
    const message = error.data?.errors?.[0];

    if (message) {
      return message;
    }

    if (error.status === 404) {
      return t('common.errors.notFound');
    }

    return t('common.errors.badRequest');
  }

  const requestError = ref<RequestError | null>();

  const parseError = (error: unknown): RequestError => {
    const unknownError = error as TransportError;
    const result: RequestError = {
      message: t('common.errors.loadDataFailed'),
      type: RequestErrorType.ServerError,
    };

    if (isObject(error) && unknownError.isTransportError) {
      result.originalResponse = {
        status: unknownError.status,
        statusText: unknownError.statusText,
        data: unknownError.data,
        headers: unknownError.headers,
      };
      if (unknownError.status >= 400 && unknownError.status < 500) {
        result.message = getTransportErrorMessage(unknownError);
        result.type = RequestErrorType.BadRequest;
      }
    }
    requestError.value = result;

    return requestError.value;
  };

  const clearError = () => {
    requestError.value = null;
  };

  return { requestError, parseError, clearError };
}
