import { defineStore } from 'pinia';
import { getDecimalSeparator, getThousandSeparator, getTextDirection } from '../services/i18n';
import { StoreNames } from '../shared/store-names';

interface State {
  locale: string;
  decimalSeparator: string;
  thousandSeparator: string;
  textDirection: 'ltr' | 'rtl';
}

export const useI18nStore = defineStore(StoreNames.I18n, {
  state: (): State => ({
    locale: '',
    textDirection: 'ltr',
    decimalSeparator: '',
    thousandSeparator: '',
  }),
  actions: {
    setLocale(locale: string) {
      this.locale = locale;
      this.decimalSeparator = getDecimalSeparator(locale);
      this.thousandSeparator = getThousandSeparator(locale);
      this.textDirection = getTextDirection(locale);
    },
  },

  persist: {
    afterRestore(context) {
      // TODO: Remove by March 2025
      const oldState = window.localStorage.getItem('onebeat-app:i18n');
      if (!oldState) {
        return;
      }

      const parsedState = JSON.parse(oldState);
      try {
        context.store.$state = parsedState;
      } catch (err) {
        // do nothing
      }

      context.store.$persist();
      window.localStorage.removeItem('onebeat-app:i18n');
    },
  },
});
